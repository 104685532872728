.dicomViewer{
    width: 75vw;
    height: 72vh;
    top: 0px;
    left: 0px;
    position: absolute;
}

.dicomImageWrapper{
  width: 75vw;
  height: 72vh;
  position: relative;
  color: white;
}

button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-Clicked{
  background-color: palegreen;
}

.container {
  padding: 20px;
}